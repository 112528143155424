.star-rating-container {
    text-align: center;
    margin: 20px;
}

.star-rating {
    font-size: 24px;
    cursor: pointer;
}

.star {
    color: gainsboro;
    transition: color 0.2s;
    margin-right: 5px;
    font-size: 20px;
    /* outline: 1px solid black; */
}

.star.selected {
    color: black;
}

.rating-label {
    font-size: 18px;
    margin-bottom: 10px;
}

.modal {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* max-width: 200px; */
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
}

@media(max-width:1000px) {
    .modal {
        margin-left: -5rem;
        margin-top: 20rem;
    }
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    background-color: #100840;
    width: 80%;
    flex-direction: column;
}

.modal-text {
    font-size: 18px;
    color: aliceblue;
}

.check-mark {
    color: #4caf50;
    font-size: 50px;
    animation: checkmark 2s ease-in-out;
    animation-iteration-count: 3;
}

.modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes checkmark {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}