main {
    min-height: calc(100vh - 3em);
    padding: 2em 0;
}

main h1:nth-child(1) {
    font-weight: bolder;
    text-align: center;
    margin: .75em 0;
}

form {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2em auto;
}

form input,
form select,
form button,
form a {
    width: 100%;
    margin: .75rem 0;
    padding: .75em 0;
    line-height: 1em;
}

form input,
form select {
    padding: 1em .5em;
    outline-color: #d1d1d1;
    outline-width: 1px;
    outline-style: solid;
}

form a {
    text-align: center;
    text-decoration: none;
    font-size: .75rem;
}

form button {
    background-color: var(--secondary);
    color: var(--white);
    border: none;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: .1em;
}

.container {
    width: 90%;
    margin: 0 auto;
}


.auth-container {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    padding: .5em;
    background-color: var(--white);
}


.auth-menu {
    width: 100%;
    margin-bottom: 2em;
    padding: 1em 0;
    border-bottom: 1px solid rgba(0, 0, 255, 0.25);
    position: relative;
    text-align: center;
}

.auth-menu ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

.auth-menu ul li {
    width: 50%;
    margin: 1em;
}

.auth-menu ul li a {
    text-decoration: none;
}

.auth-menu ul li:nth-child(1) {
    border-right: 1px solid var(--secondary);
}

.auth-menu::before {
    content: '';
    position: absolute;
    display: block;
    top: 100%;
    width: 50%;
    height: 1px;
    background: red;
    border-bottom: 1px solid rgba(0, 0, 255, 0.5);
}

.auth-menu__left::before {
    left: 0;
}

.auth-menu__right::before {
    left: 50%;
}

.form-group>label {
    text-transform: uppercase;
}


.radio-group {
    width: 100%;
    display: flex;
}

.radio-group section {
    margin-right: 2em;
}

.label-group {
    font-weight: bold;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    width: max-content;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}

.label-group input {
    width: 1.25em;
    height: 1.25em;
    padding: .23rem;
}

.label-group label {
    margin-left: 1em;
    font-weight: 400;
    font-size: var(--default-font);
}

.contact__preference {
    width: 100%;
    padding: 2em 0;
}

.contact__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.contact__header section {
    text-align: left;
}


.contact__header p {
    font-weight: lighter;
}

.contact__header button {
    width: max-content;
    padding: .5em .75em;
    font-size: var(--default-font);
}

.checkbox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 1em 0;
}

.checkbox__item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.checkbox__item label {
    min-width: max-content;
    font-weight: lighter;
}

.checkbox__item input {
    width: 1.25em;
    height: 1.25em;
    border: 1px solid currentColor;
    accent-color: black;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}

.social__button {
    font-size: 1rem;
    border: .1em solid hwb(0 0% 100% / 0.5);
    background-color: transparent;
    padding: 1em 1.25em;
    margin-right: 1em;
    border-radius: calc(var(--rounded-sm));
    display: flex;
    justify-content: center;
    align-items: center;
}


.social__button span {
    text-transform: uppercase;
    font-weight: bold;
    ;
}

.social__button img {
    width: 1em;
    object-fit: cover;
    margin-right: 1em;
}

.social__header {
    font-size: 1.15em;
    font-weight: bolder;
    text-align: center;
    margin: 2em 0;
    text-transform: uppercase;
}

.social__text {
    width: 95%;
    font-size: .8em;
    text-align: center;
}

.signin_form {
    margin-top: 4em !important;
}

.joinButton {
    transition: 700ms all;
    border-radius: 100px;
    background-color: aliceblue;
    color: #222;
    padding: 10px;
}

.joinButton.active,
.joinButton:hover {
    padding: 12px 15px;
    border-radius: 100px;
    background-color: #333;
    color: aliceblue;

}

/* password hide icon */
.password-input-wrapper {
    position: relative;
}

.password-toggle-button {
    width: auto;
    display: inline;
    position: absolute;
    top: 40%;
    left: 90%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 1.2rem;
    color: #888;
}



@media (max-width:40em) {
    .radio-group {
        flex-direction: column;
    }

    .contact__header {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .social {
        flex-direction: column;
    }

    .social__button {
        width: 90%;
        justify-content: center;
        margin: .5em 0;
    }

    .social__button span {
        margin: 0 2em;
    }
}