.review-page {
    text-align: center;
    padding: 20px;
}

.customer-rating {
    margin: 20px 0;
}

.recent-reviews {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.review-card {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: left;
}