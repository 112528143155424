.topBanner {
    width: 100%;
    background-color: #100840;
    height: 20vh;
    /* margin-bottom: 30px; */
    margin-top: 0;
    padding: 0 1rem;


}

.bannertop {
    /* background: linear-gradient(#ff89f5, #98ff5dcb); */
    background-color: #100840;
    padding: 60px 1rem;
    margin-bottom: 15px;

}

.innerbanner {
    display: flex;
    width: 100%;
    height: inherit;
    justify-content: center;
    align-items: flex-start;
    margin-top: -8px;
}

.big-text {
    color: #eee;
    font-size: 15px;
}

.small-text,
.medium-text {
    color: #eee;

}


.mainBanner {
    height: 10vh;
    width: 50%;
    background-color: #100840;
    display: flex;
    align-items: center;
    justify-content: center;

}

.mainBanner1 {
    width: 50%;
    height: 10vh;
    background-color: #100840;
    display: flex;
    align-items: center;
    justify-content: center;

}

@media(max-width:900px) {
    .topBanner>.innerbanner>.mainBanner {
        display: flex;
        align-items: center;
    }

    .topBanner>.innerbanner>.mainBanner>p {
        font-size: 9px;

    }

    .bannertop {
        padding: 2rem 3rem;
    }

    .small-text,
    .medium-text {
        font-size: 16px;

    }
}