.mainProduct {
    padding: 0 15rem;
}

@media(max-width:1000px) {
    .mainProduct {
        padding: 0 1rem;
    }
}

.accordion-body {
    text-align: left !important;
}

.preproduct {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 2em;
    padding: 2em 0;
}

.SelectSize {
    background-color: #100840 !important;
    color: aliceblue !important;
    height: 30px;
    width: 100%;

}

.SelectSize1 {
    background-color: #eee !important;
    color: rgb(124, 124, 124) !important;
    height: 30px;
    width: 3rem;

}

.SelectSize>option {
    color: aliceblue !important;
    padding: 1rem 0;
}

.sticky {
    top: 0;
    position: static !important;
    position: -webkit-sticky;
}

.preproduct__images {
    display: grid;
    grid-template-columns: 0% 100%;
    align-items: flex-start;
    gap: 0;
    position: relative;
}

.bagheart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bagheart>.heartProduct {
    width: 10%;
    height: 10%;
    border: #100840 1px solid;
    padding: 6px;
    border-radius: 100px;
}

.bagheart>.bagProduct {
    width: 85%;
    background-color: #100840;
    height: 45px;
}

.preproduct__images--side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.preproduct__images-sticky {
    position: sticky;
    top: 700px;
    /* Adjust the value as needed */
    z-index: 1;
    /* ... (other styles) */
}

@media(max-width:1000px) {
    .preproduct__images-sticky {
        top: 0px;
        position: static;

    }

}


.preproduct__images--side button {
    cursor: pointer;
    border: none;
    margin: .5em;
    outline: none;
}

.preproduct__images--side button:focus {
    border: none;
    box-shadow: 2px 2px 2px var(--light-gold);
}

.preproduct__images--side img {
    max-width: 3em;
}

.preproduct__images--main {
    width: 100%;

}

.preproduct__images--main-controls {
    width: 75%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.preproduct__images--main-controls button {
    background-color: transparent;
    border: none;
    opacity: .5;
}

.preproduct__images--main img {
    width: 100%;
    height: 450px;
    cursor: zoom-in;
    margin-top: 3rem;
    max-width: 70%;
}

@media(max-width:1000px) {

    .preproduct__images--main img {

        margin-top: 0rem;
    }
}


.preproduct__details {
    width: 100%;
}

.preproduct__details--name,
.preproduct__details--price,
.preproduct__details--buttons {
    display: flex;
    flex-direction: column;
}

.preproduct__details--name {
    justify-content: space-between;
}

.mainsize {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.preproduct__details--name button {
    background-color: transparent;
    border: none;
}

.preproduct__details--price h2 {
    font-size: 1.5rem;
    margin: .25rem;
}

.preproduct__details--price h3 {
    text-decoration: line-through;
    margin-left: .25rem;
}

.preproduct__details--price-inactive {
    color: var(--dark-gold);
}


.preproduct__details--buttons {
    /* flex-direction: column;
    margin-bottom: 1em; */
}

.preproduct__details--buttons select {
    background-color: #FFF;
    border: 1px solid var(--dark-gold);
}

.preproduct__details--buttons select,
.preproduct__details--buttons button {
    /* padding: 1em; */
    margin: .5em 0;
}

.preproduct__details--buttons button {
    margin: left 0.5rem !important;
    background-color: #222;
    color: var(--light-gold)
}

.preproduct__details--buttons button span {
    padding: .25rem;
}

.preproduct__details--info {
    margin: 1em 0;
    background-color: #10084005;
    border-radius: 4px;
    box-shadow: #1008403a 2px 3px 7px;
    padding: 0.5rem 1rem;
}

.preproduct__details--info-selector {
    display: flex;
    margin: 1em 0;
}

.preproduct__details--info-selector button {
    margin-right: .5rem;
    padding: .5em;
    border-radius: 2rem;
    border: none;
    background-color: transparent;
}

.preproduct__details--info-selector--active {
    background-color: var(--light-gold) !important;
    color: var(--dark-gold);
}

.preproduct__details--info .header__text,
.preproduct__details--info .about__text,
.product__info {
    margin: 1em 0;
}

.product__info {
    width: 100%;
}

.product__info--attr {
    display: flex;
    margin: .5em 0;
}

.product__info--attr h6 {
    width: 15%;
}

.product__info--attr span {
    width: 80%;
    opacity: .75;
}


.reproducts {
    width: 100%;
    margin: 1em auto;
}

.reproducts h1 {
    text-align: center;
    font-size: clamp(2.5em, 5vw, 3em);
    margin: 1em 0;
}

.reproducts__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.reproducts__grid-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 20%;
    /* background-color: #100840; */
    margin: 1rem 0;
}

.reproducts__grid-item a {
    color: #222;
}

.reproducts__grid-item img {
    width: 100px;
    object-fit: cover;
    height: 130px;


}

.reproducts__grid-item-content {
    position: relative;
    object-fit: cover;
    width: 90%;


}

.reproducts__grid-item--controls {
    position: absolute;
    bottom: 5%;
    left: 60%;
}

.reproducts__grid-item--price {
    font-weight: 400;
}

.reproducts__grid-item--controls button {
    border: none;
    background-color: #ffffffc0;
    padding: 7px;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

@media(max-width:1000px) {
    .preproduct__details {
        margin-top: 10rem;
    }

    .preproduct__images--side {
        position: relative;
        top: 38rem;
    }
}

@media (max-width:65em) {
    .reproducts__grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 55em) {
    .preproduct {
        grid-template-columns: 100%;
    }

    .preproduct__images {
        grid-template-columns: 100%
    }

    .preproduct__images--side {
        width: 100%;
        /* position: absolute;
        top: 100%;*/
        left: 45%;
        transform: translate(-50%, -50%);
        z-index: 2;
        padding: 1em 0;
        flex-direction: row;
        justify-content: space-evenly;

    }

    .preproduct__images--side button {
        width: 1em;
        height: 1em;
        border: 1px solid var(--dark-gold);
        border-radius: 1em;
        margin: 0 1em;
    }

    .preproduct__images--side img {}

    .preproduct__images--main-controls {
        display: none;
    }

    .reproducts__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (max-width:35em) {
    .reproducts__grid {
        grid-template-columns: 100%;
    }

    .reproducts__grid-item {
        width: 30%;
    }

    .reproducts__grid-item img {
        width: 100%;
        min-height: inherit;
        object-fit: cover;

    }
}