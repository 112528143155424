.row,
.mainFooter {
    overflow-x: hidden !important;

}

.mainFooter {
    height: inherit;
    background: #100840;
    padding-bottom: 40px;

}

.holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerBold {
    color: #fff !important;
    font-size: 16px;
    padding: 20px 10px;
    font-weight: 700;
}

.help {
    text-decoration: none;
    color: aliceblue;
    text-align: left;
    margin-left: 2rem;
}

.help:hover {
    color: #4270d2;
}

@media(max-width:800px) {
    .holder {
        justify-content: left;
        align-items: flex-start;
    }

    .footerBold {
        font-size: 10px;
    }

    .help>p {
        font-size: 8px;
    }


}

.ads2 {
    width: 100%;
    height: 2rem;
    background-color: #10084054;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 20px;

}

.ads2>p>.link {
    font-size: 10px;
    color: #222;
    text-decoration: none;
    border-left: 1px solid #2222229a;
    padding: 0 5px;
}

.ads2>.link {
    font-size: 10px;
    color: #222;
    text-decoration: none;
    padding: 5px 15px;
}