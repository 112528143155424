.topmost2 {
  font-size: 60px;
  color: #333;
  position: relative;
  height: 100%;
  z-index: 0;
  /* background-color: #333; */
  margin-top: 0;
}

/* swiper styles */

.swiper {
  width: 100%;
  height: 100vh;
  background-size: contain;

}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: inherit;
  object-fit: cover;
  background-position: bottom;
}

.swiper-slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000092;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
}

.swiper-slide-overlay h2 {
  font-size: 44px;
  margin-bottom: 10px;
  font-weight: 700;

}

.swiper-slide-overlay p {
  font-size: 16px;
  text-align: center;

}


/* end of swiper styles */