@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,600;0,800;1,400;1,600;1,700&family=Tilt+Neon&display=swap');

* {
  font-family: 'EB Garamond', serif;
  /* font-family: 'Tilt Neon', cursive; */
  font-size: 13px;
}

.overall {
  position: relative;
  z-index: 999;
}

.topmenu1 {
  width: 100%;
  height: 3rem;
  background-color: #fff;
  display: flex;
  justify-content: right;
  align-items: center;

}

.topmenu1>.innermenu {
  padding: 0 10px;
  font-size: 12px;
  color: #7b7b7b;
  border-right: 1px solid #1008404e;
  border-left: 1px solid #1008404e;
  width: 20%;
  height: inherit;
  display: flex;
  align-items: center;

}

.topmenu2 {
  width: 100%;
  height: 5rem;
  background-color: #202020;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.topmenu2>.left {
  width: 50%;
  background-color: #202020;
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;

}


.topmenu2>.left>.category {
  /* background-color: #ffffff; */
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 1.5rem;
  color: #e2e2e2;
  text-decoration: none;
  height: 10vh;
}

.topmenu2>.left>.men {
  /* background-color: #333; */
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  padding: 0 1.5rem;
  color: #100840;
  text-decoration: none;
}


.topmenu3 {
  width: 100%;
  height: 5rem;
  /* background-color: #202020; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}



.topmenu3>.left {
  width: 100%;
  /* background-color: #202020; */
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* flex-direction: column;
  position: relative;
  z-index: 999;
  line-height: 60px; */


}

.topmenu2>.left>.logo {
  font-variant: small-caps;
  font-size: 20px;
  font-weight: 900;
  /* color: #100840; */
  text-decoration: none;
  width: 20%;
  border-right: 1px solid rgba(240, 248, 255, 0.623);


}


.topmenu3>.left>.category {
  /* background-color: #333; */
  font-size: 28px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  color: #e2e2e2;

}

.topmenu3>.left>.logo {
  /* background-color: #333; */
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  color: #100840;
  text-decoration: none;
  display: none;

}

.topmenu3>.left>.men {
  /* background-color: #333; */
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;
  color: #100840;

  padding: 0 1.5rem;
}

.topmenu2>.left>.active {
  background-color: #333;

}

.topmenu3>.left>.active {
  color: #fff;
  padding: 5px;
  width: 40%;
  background-color: #333;
}


.topmenu3>.left>.active~.menu-item {
  display: none;
}


.topmenu2>.center {
  width: 35%;
  /* background-color: #100840; */
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

}

.topmenu2>.center>.search {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 0% !important;
}

.searchBox,
.searchBox>input,
.searchBox>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 2rem;
  border-radius: 300px;
  text-align: center;
  font-size: large;
  box-shadow: 1px 1px 5px #202020;
  margin: 0;
}

.searchicon {
  font-size: 30px;
  color: #100840;
}

.minilogo {
  color: #222;
  /* text-decoration: none; */
  font-weight: 900;

}

.searchBox>button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: inherit;
}

.topmenu2>.right {
  width: 15%;
  /* background-color: #333; */
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;


}

.offcanvas-top {
  display: flex;
  background-color: transparent;

}

.mycanvas {
  width: 40%;
  background-color: #222;
  align-self: center;
  height: inherit;
}

@media (max-width: 767px) {
  .offcanvas-top {
    width: 100%;
  }
}

.myAuth {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.auth {
  color: #fff;
  font-size: 25px;
  font-family: 'Tilt Neon', cursive;
  text-decoration: none;

}

.auth:hover {
  color: #bfb4ff;
}

button[data-bs-dismiss="offcanvas"] {
  color: #9e8dff !important;
}

.myAcount {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  height: 35px;
  background-color: #333;
  border-radius: 0 12px 0 0;
}

.account {
  color: #fff;
  /* font-family: 'Tilt Neon', cursive; */
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;


}

.account>h4 {
  font-size: 10px;
  margin: 0 10px;

}

.account>.icon {
  font-size: 25px;
}

.account:hover {
  color: #ffffff !important;
}


.usericon {
  font-size: 46px;
  padding: 8px 0px;
  margin: 0 0rem;
  transition: all 2s;
  color: #fff;
  font-weight: lighter;
  /* Adjust font weight as needed */
}

.usericon:hover {
  border: 1px solid aliceblue;
  border-radius: 5px;
}

.mobileusericon {
  font-size: 40px;
}

.mainlogo {
  font-variant: small-caps;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 15px;
  color: #fff;
}

.topmost {
  padding: 0 !important;
  margin: 0 !important;
  background-color: #100840 !important;
}

.hover-menu {
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;
  background-color: #333;
  height: 4vh;
  perspective: 800px;


}

.mainmenu {
  display: flex;

}

.menu {
  width: 100%;
  margin: 0;
  padding: 0.9rem;
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  list-style: none;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: safe;
  justify-content: center;
  overflow-y: auto;
  /* overflow: auto; */


}

@media(max-width:1000px) {
  .datahorizontal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .datahorizontal img {
    margin: 7px 0;
  }

  .datahorizontal .menutext {
    margin: 10px 0;
    font-size: 12px;
    padding: 8px;
  }


}


.container-fluid .right {
  display: flex;
  justify-content: space-between;

}


.menutext {
  max-width: 50%;
  width: 30rem;
  display: flex;
  flex-wrap: wrap;
  max-height: inherit;
  justify-content: space-around;

}

.menutext:hover {
  color: #0000c1;
}

.menuimage {
  width: 100px;
  object-fit: cover;
  height: 100px;
  border-radius: 100px;
}

.menulink {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 2rem;
  /* flex-wrap: wrap; */
  padding: 20px 0;

}


.menulink2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.line {
  width: 100%;
  height: 10px;
  /* background-color: #7b7b7b; */
  display: flex;
  justify-content: center;
  margin: 10px 0;

}

.innerline {
  width: 50%;
}

.innerline2 {
  width: 20%;
}

.datahorizontal {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */

}

.datahorizontal>.menuimage {
  width: 50px;
  height: 50px;
  margin: 0;
}

.menutext {
  font-size: 11px;

}

.datahorizontal>.menutext {
  width: 50px;
  height: 50px;
  margin: 0;
  font-size: 11px;
  display: flex;
  align-items: center;
}


.menu li {
  margin: 0;
  padding: 0.5rem;
  font-size: 1rem;
}

.menu li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.menu li a i {
  margin-right: 0.5rem;
}

.container-fluid {
  background-color: #ffffff;
  padding: 0 !important;
  margin: 0 !important;


}



.megainner1 {
  background-color: #222;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 2.2rem;


}


.menu2 {
  display: flex;
  width: 100%;
  background-color: #333;
  justify-content: center;
  height: inherit;
  align-items: center;
  transform-style: preserve-3d;
  position: relative;
  z-index: 9999;
  margin-top: 3rem;
}

.menu2>.link {
  background-color: #333;
  width: 8rem;
  height: inherit;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;


}

.menu2>.link:hover {
  background-color: #fff;
  color: #100840;
  transform: translateY(0px) rotateX(360deg);
  border: 1px solid #222;
  /* border-radius: 10px; */
}

.menu2>.speciallink {
  background-color: #100840;
  transform: skewX(-15deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  margin: 0 0.8rem;
  max-height: 20px;
}

@media(max-width:1000px) {
  .menu2>.speciallink {

    max-height: 50px;
  }

  .menu2>.link {

    max-height: 50px;
  }

  .menu2 {

    max-height: 50px;
  }
}

.menu2>.speciallink>span {
  font-size: 15px;
}

.menu2>.link>span {
  font-size: 15px;
}


.menu2>.speciallink:hover {
  transform: skewX(0deg);
  border-radius: 0px;

}

.menu2>.speciallink>span {
  transform: skewX(15deg);
}

.menu2>.speciallink:hover span {
  transform: skewX(0deg);
}


.offcanvas {
  margin-left: auto;
  margin-right: auto;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.offcanvas.show {
  transform: translateX(0);
}


@media(max-width:1000px) {

  .menu2,
  .menu {
    flex-direction: column;
    display: inline-block;
    width: 100%;


  }


  .menu {
    margin: 7rem 0;
    max-height: 90vh;
    flex-wrap: wrap;
    display: flex;
  }

  .megainner1 {
    background-color: #202020;
    width: 100%;

  }

  .menutext {
    max-width: 50%;
    width: 6rem;
    display: inline-block;
  }

  .menu2>.link {
    background-color: #202020;
    margin: 2rem 0;
    width: 100%;

  }

  .menu2>.speciallink {
    background-color: #100840;
    margin: 2rem 0;
    width: 100%;
    transform: skew(0deg);
    border-radius: 200px;

  }

  /* .menu2>.link {
    display: none;
  }

  .menu2>.link:hover {
    display: block;
    background-color: red;
  } */

  .menu2>.speciallink>span {
    transform: skewX(0deg);
  }

  .hover-menu {
    height: 220vh;
    width: 100%;

  }

}

.titleText {
  /* background-color: #4270d2 !important; */
  /* max-height: 40px !important; */
  width: 100% !important;
  margin-top: -20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: -1.3rem;


}

.bodyText {
  /* background-color: #d24242 !important; */
  max-height: 40px !important;
  width: 100% !important;
  /* min-height: 40px; */
  /* margin-top: 10rem; */


}

@media(max-width:1000px) {

  .topmenu2,
  .topmenu1 {
    display: none;
  }

  .menutext {
    font-size: 9px;
  }

  .menutitle {
    font-size: 12px;
    width: 30%;
    column-count: 5;
    display: flex;
  }



  .category,
  .men {
    color: #4270d2;
    text-decoration: none;
  }

  .offcanvas-header>.right {
    width: 40%;
    display: flex;
    /* background-color: #222; */
  }

  .topmenu3>.left>.category,
  .topmenu3>.left>.men {
    font-size: 10px;
    padding: 0;
    margin-left: 5px;
  }
}

@media(min-width:1000px) {

  .topmenu3,
  .mainlogo {
    display: none;
  }

}

@media(max-width: 1000px) {
  .hideMobleMenu {
    display: none !important;
  }

  .showMobleMenu {
    display: block !important;
  }
}

/* Lubem Experiment With Mobile Menu */
@media(max-width: 767px) {
  .hideMobleMenu {
    display: none !important;
  }

  .showMobleMenu {
    display: block !important;
  }

  .topmenu3>.left>.active {
    color: #fff;
    padding: 5px;
    width: 30%;
    background-color: #333;
  }
}

.mediaBackMenu {
  color: white;
  font-size: 10px;
  background-color: #100840;
  /* background-color: #4270d2; */
  padding: 10px 30px;
  width: 100%;
  border-radius: 100px;
  margin-top: 1000px;
  text-decoration: none;
}

@media(min-width:1000px) {
  .mediaBackMenu {
    display: none;
  }
}

.right1 {
  display: flex;
  padding: 0 10px;
}

@media(min-width:1000px) {
  .right1 {
    display: none;
  }
}


/* for search dropdown */
.center {
  position: relative;
}

.center>ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 80%;
  /* Position the dropdown below the search input */
  left: 5.5%;
  width: 79.9%;
  background-color: #100840;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* display: none; */
  /* Hide the dropdown by default */
  z-index: 1;
  /* Add a z-index to ensure it's above other elements */
  border-radius: 30px 0 30px 30px;
}

.center:hover .center>ul {
  display: block;
  /* Show the dropdown on hover of the parent */
  border: 1px solid #ccc;

}

.center>ul>li {
  padding: 10px;
}

.center>ul>li:nth-child(even) {
  border-bottom: 1px solid #ccc;

}

.center>ul>li:nth-child(odd) {
  border-bottom: 1px solid #ccc;

}

.center>ul>li:last-child {
  border-bottom: none;

}

/* Style for the search input (you can adjust this according to your design) */
input[type="text"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
}

.searchBox>.form-control {
  border-top-left-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}

.marketplace {
  width: 30%;
  height: 30vh;
  background-image: linear-gradient(#000000c4, rgba(0, 0, 133, 0.479)), url(../img/14.jpeg);
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  filter: contrast(90%);
  filter: sepia(90%);
  filter: saturate(90%);
  float: right;
}

.marketplace>h4 {
  color: #fff;
  /* margin-bottom: 2rem; */
  font-size: 30px;
  background-color: #202020;
  padding: 10px;
  width: 100%;


}

.marketplace>.bg {
  background-color: #ffffffe5;
  width: 100%;
  height: 7vh;
  color: #202020;
  /* text-transform: uppercase; */
  font-variant: small-caps;
  font-size: 20px;
}