.mainads {
    background-color: #100840;
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 99;
    margin: .5rem 0;
}

.mainads>.p1 {
    background-color: #b4abeb;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 0 1rem;


}


.mainads>.p2 {
    /* background-color: #000000; */
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}