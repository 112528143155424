.swiper1 {
  width: 65%;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.swiper-slide1 {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: calc((50% - 30px) / 2);

  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;

}

.swiper-slide1>img {
  width: 100%;
  object-fit: cover;
  height: 80vh;

}

@media (max-width:900px) {

  .swiper1 {
    height: 60vh;
    width: 100%;
    overflow-x: hidden;
  }

}

@media (max-width:500px) {

  .swiper1 {
    height: 30vh;
    width: 100%;
    overflow-x: hidden;

  }
}

.gridtitle {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 800;
  color: black;
  text-decoration: none;
}

.gridText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #003cff0c;
  padding-top: 10px;
  padding-bottom: 10px;
}

.griddesc {
  font-size: 18px;
  text-transform: lowercase;
  color: #6b6b6b;
  text-decoration: none;

}

@media(max-width:1000px) {
  .gridtitle {
    font-size: 18px;
  }
}