/* paypal button */
.myPaypal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* background-color: #00072f; */
    margin-top: 5rem;

}

.paystackBtn{
    width: 60%;
    padding:20px 40px;
    background-color: rgb(7, 7, 132);
    color:white !important; 

}

@media(max-width:1000px) {
    .myPaypal {
        height: auto;
        margin-top: 6rem;

    }
}