.readmoreProduct {
    width: 100%;
    display: flex;
    height: auto;
    justify-content: center;

}

.readmoreProduct>div {
    max-width: 50%;

}

.readmoreProduct>div>p {
    font-size: 15px;
}