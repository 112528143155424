.mainbody>article>h1 {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
}

.mainbody>article>div>p {
    font-size: 20px;
}

.mainbody {
    /* padding: 0 5rem; */
    margin: 0;
    width: 100%;
    height: 150vh;
}


.mainbody {
    text-align: center;
    color: #fff;
    font-family: Open Sans;
    font-style: italic;
    background: #100840;

}


.mainbody {
    font-family: Open Sans;
    font-weight: 100;
    font-size: 20px;
    color: #fff;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

article {
    display: block;
    width: 700px;
    padding: 50px;
    margin: 0 auto;
}

@media(max-width:900px) {
    article {
        margin-top: -10rem;

    }

    .mainbody {
        height: 120vh;
    }
}

.icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 1rem 0;
}

a {
    color: #fff;
    font-weight: bold;
}

a:hover {
    text-decoration: none;
}

.svg {
    width: 30px;
    /* margin-top: 1em; */
}



.fas {
    padding: 20px;
    font-size: 30px;
    width: 70px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;

}

.fa:hover,
.x:hover {
    opacity: 0.7;
}

.fa-facebook {
    background: #3B5998;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-x {
    background: #030303;
    color: white;
    border-radius: 200px;
    padding: 15px;
    width: 70px;
    height: 70px;

}



.fa-instagram {
    background: #125688;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center
}

.ticktok {
    background: #fff;
    padding: 15px 19px;
    display: inline-block;
    color: #030303;
    display: flex;
    justify-content: center;
    align-items: center
}


.mainbody {
    background: linear-gradient(350deg, #808080, #0000ff, #008000, #ff0000, #ffff00);
    background-size: 1000% 1000%;

    -webkit-animation: AnimationName 20s ease infinite;
    -moz-animation: AnimationName 20s ease infinite;
    -o-animation: AnimationName 20s ease infinite;
    animation: AnimationName 20s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 2% 0%
    }

    50% {
        background-position: 99% 100%
    }

    100% {
        background-position: 2% 0%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 2% 0%
    }

    50% {
        background-position: 99% 100%
    }

    100% {
        background-position: 2% 0%
    }
}

@-o-keyframes AnimationName {
    0% {
        background-position: 2% 0%
    }

    50% {
        background-position: 99% 100%
    }

    100% {
        background-position: 2% 0%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 2% 0%
    }

    50% {
        background-position: 99% 100%
    }

    100% {
        background-position: 2% 0%
    }
}

@media(max-width:560px) {
    .mainbody>article>h1 {
        font-size: 20px;
        font-weight: 100;
        text-align: center;
    }

    .mainbody>article>h1 {
        font-size: 20px;
        font-weight: 100;
        text-align: center;
    }

    .mainbody>article {
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        flex-direction: column;
    }

    .mainbody>article>div>p {
        font-size: 10px;
    }


    .fas {
        font-size: 20px;
        padding: 10px;
        width: 30px;
        height: 30px;

    }

    .check-x {
        height: 30px;
        width: 30px;
        padding: 7px;

    }

}