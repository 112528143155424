.mainSocial1 {
  width: 100%;
  height: 7vh;
  background-color: #e8e8e8;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
}

/* .mainSocial>
  .left {
    width: 100%;
    display: flex;
    height: inherit;
    justify-content: flex-end;
    background-color: white;
  }

  .mainSocial>.left > .img {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    height: inherit;
    padding-left: -1rem;
    padding-right: 1rem;
  }
  .mainSocial >.left > .img > img {
    width: 60%;
    object-fit: scale-down;
  } */

.mainSocial1>.right {
  width: 100%;
  display: flex;
  background-color: white;
  height: inherit;
  justify-content: flex-start;
}

.mainSocial1>.right>.img {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  height: inherit;
  padding-right: -1rem;
  padding-left: 1rem;
}

.mainSocial1>.right>.img>img {
  width: 60%;
  object-fit: scale-down;
}

@media(max-width:900px) {

  .mainSocial1>.right>.img,
  .left>.img {
    width: 18%;
  }

  .mainSocial1>.left {
    width: 45%;
  }

  .mainSocial1 {
    margin-left: -2rem;
  }
}

.socialX {
  background-color: black;
  width: 50px;
  height: 50px;
  border-radius: 300px;
  padding: 10px;
}

@media(max-width:1000px) {
  .socialX {
    border-radius: 300px;
    padding: 2px;
    width: 70px;
    background-color: transparent;
    color: black;

  }
}