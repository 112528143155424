.cartMain {
    background-color: #EEE;
}

.cart__checkout--details-info {
    font-size: larger;
    padding: 2rem 0;
}

.cart {
    padding: 5rem 0;
    margin: 2em auto !important;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-auto-rows: auto;
    gap: 1em;
    justify-content: space-between;
}

.size_qty {
    display: flex;

}

.size_qty>.qty {
    margin: 0 1rem;
    width: 100px;
    display: flex;
    padding: 0 .5em;
    align-items: center;
}

#quantity {
    background-color: #eee;
    text-align: center;
    width: 50%;
}


.cart>section {
    width: 100%;
    padding: 1em;
}

@media(max-width:1000px) {}

.cart__items {
    height: inherit;
    width: 100%;
}

.cart__items--header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #fff;
    padding: 2rem 0;
    box-shadow: 1px 3px 10px #c2c2c2;
    height: inherit;

}

@media(max-width:1000px) {
    .cart__items--header {
        width: 100%;
        color: #000;
        box-shadow: 1px 3px 10px #c2c2c2;

    }

    .cart__item--image {
        width: 100%;
        height: 100%;
        background-size: cover;
    }
}

.cart__item {
    width: 100%;
    display: flex;
    margin: 1em 0;
    height: inherit;

}

.cartitem_in_item {
    display: flex;
    background-color: #fff;
    flex-wrap: wrap;

}

.cartitem_in_item>section {
    width: 40%;
}

.cartitem_in_item>div {
    width: 60%;
    padding: 30px;
}

@media(max-width:1000px) {
    .cartitem_in_item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .cartitem_in_item>div {
        width: 100%;
    }

    .cartitem_in_item>.cart__item--image {
        width: 100%;
        height: 50vh;

    }

    .cartitem_in_item>.cart__item--image>img {
        width: 100%;

    }
}

.cart__items .container_cart__item {
    border-bottom: 1px solid var(--black-dark);
}

.cart__items .container_cart__item:last-child {
    border-bottom: none;
}

.cart__item--image img {
    width: 100%;
    object-fit: contain;
    /* background-color: #100840; */
    padding: 10px;

}

.cartDetails {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
}

.cart__item--details {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    padding: 0 1em;
    background-color: #fff;
    box-shadow: 1px 3px 10px #c2c2c2;

}

.cart__item--price {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.cart__item--price section {
    margin-top: .5rem;
}

.cart__item--price--order {
    font-size: 1.25rem;
}

.cart__item--price--original {
    margin-right: .25rem;
    text-decoration: line-through;
}

.cart__item--price--discount {
    background-color: var(--light-gold);
    padding: .25rem;
    margin-left: .25rem;
}

.cart__item--price--discount::after {
    content: '%';
}

.cart__item--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cart__item--flex button,
.cart__item--flex-item {
    height: 100%;
    margin: .5rem 0;
}

.cart__item--flex button {
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem;
    opacity: .5;
}

.cart__item--flex button span {
    margin: 0 .25rem;
    font-size: .9rem;
    font-weight: 400;
}

.cart__item--flex-item span {
    text-transform: capitalize;
    opacity: .75;
}

.cart__item--flex-item span:nth-child(1):after {
    content: ':';
    margin-right: .5rem;
}

.cart__checkout {
    height: inherit;
    background-color: #fff;
    color: #222;
    border-radius: .25em;
    margin: 1rem 0;
}

.cart__checkout h2 {
    padding-bottom: .5em;
    margin: .5em 0;
    border-bottom: 1px solid #000;
}

.cart__checkout--details {
    padding: 1em 3rem;
    margin: 1em 0;
    border-bottom: 1px solid #000;

}

.cart__checkout>.checkoutbtn {
    background-color: #100840;
    color: #EEE;
    text-decoration: none;
    display: block;
    font-size: 20px;
}

.cart__checkout>.checkoutbtn:hover {
    background-color: #180d55;
    color: #bdebf3;
}

.cart__checkout--details-subtotal {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 1rem 0;
}

.cart__checkout button {
    width: 100%;
    padding: .5em;
    background-color: var(--light-gold);
    color: var(--black-dark);
    border-radius: .25em;
}

.cart__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart__buttons button {
    border: none;
}

.cart__buttons--remove {
    color: red;
    display: flex;
    background-color: transparent;
    align-items: center;
}

.cart__buttons--quantity {
    display: flex;
    align-items: center;
}

.cart__buttons--quantity button {
    width: 1em;
    height: 1em;
    display: grid;
    place-content: center;
    border-radius: 100%;
    background-color: var(--dark-gold);
    color: var(--white);
    font-size: 1.75rem;
}

.cart__buttons--quantity span {
    margin: 1em;
}

.cart__buttons--remove {
    height: max-content;
}

.cart__buttons--remove span {
    margin-left: .25em;
}

.hidden {
    display: none;
}

@media (max-width:1000px) {
    .cart {
        grid-template-columns: 100%;
    }


    .cart__item {
        padding: 0;
        background-color: #fff;
    }

    .cart__item--image img {
        width: 100%;
        height: 100%;

    }

    .tempt {
        display: none;
    }

}

@media (max-width:35em) {
    .cart {
        grid-template-columns: 100%;
    }
}

.checkoutpayadds>h3 {
    font-size: 15px;
    text-align: left;
    margin: 1rem 0;
}

.checkoutpayaddsinner {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.checkoutpayaddsinner>.img>img {
    width: 70%;
}