.mainSocial {
  width: 100%;
  height: 7vh;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
  padding: 1.5rem 0;
}

.mainSocial>.left {
  width: 40%;
  display: flex;
  height: inherit;
  justify-content: flex-end;
  background-color: white;
}

.mainSocial>.left>.img {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  height: inherit;
  padding-left: -1rem;
  padding-right: 1rem;
}

.mainSocial>.left>.img>img {
  width: 80%;
  object-fit: scale-down;
}

.mainSocial>.right {
  width: 59.9%;
  display: flex;
  background-color: white;
  height: inherit;
  justify-content: flex-start;
}

.mainSocial>.right>.img {
  width: 12%;
  display: flex;
  justify-content: flex-start;
  height: inherit;
  padding-right: -1rem;
  padding-left: 1rem;
}

.mainSocial>.right>.img>img {
  width: 60%;
  object-fit: scale-down;
}

@media(max-width:900px) {

  .mainSocial>.right>.img,
  .left>.img {
    width: 18%;
  }

  .mainSocial>.left {
    width: 45%;
  }

  .mainSocial {
    margin-left: -2rem;
  }
}