/* Add these styles to your CSS or SCSS file */

.card-body {
    position: relative;
}

.copy-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    /* Adjust as needed */
    display: flex;
    align-items: center;
}

.copy-icon {
    margin-left: 5px;
    /* Adjust as needed */
}