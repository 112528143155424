.page__banner {
    /* padding: 20px 0; */
    background-color: #1d1c1f;
    height: 50px;
}

.page__banner h1,
.page__banner a {
    text-decoration: none;
    color: #CCC;
}

.page__banner a {
    font-weight: 400;
    opacity: .8;
}

.page__banner--container {
    display: flex;
    justify-content: space-between;
}

.page__banner--container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
}

.tempt {
    color: aliceblue;
}

.page__banner--links a:not(:last-child) {
    margin-right: .5em;
    font-size: .95rem;
    color: rgb(255, 254, 240);

}

.page__banner--links a:not(:last-child)::after {
    content: '|';
    margin-left: .5em;
    color: #CCC;
}