.address h1{
    font-size:1.5em;
}

/* Address Cards */
.address__card{
    width:100%;
    min-height:250px;
    border:3px solid #0000001b;
    padding:1em;
    margin:.75em 0 !important;
}

.address__cards a{
    color: #000000;
    text-decoration: underline;
}

.address__card--head{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:.5em 0;
    border-bottom:1px solid #0000001b;
}

.multi__form{
    min-width: 100%;
    margin:2em 0;
}

.multi__form input{
    width:100%;
    border:1px solid #0000002b;
}

.multi__form label,
.multi__form input,
.multi__form button{
    font-size:.8rem;
    font-weight:400;
}

.multi__form button{
    width:max-content;
    padding:.75em .95em;
    font-size:.7rem;
}

.input__group,
.form__input{
    width:100%;
}

.input__group{
    display:grid;
    grid-template-columns:100%;
}

.input__group--two{
    grid-template-columns:repeat(2,1fr);
    gap:2rem;
}

@media (max-width: 40em) {
    .input__group--two{
        grid-template-columns:100%;
    }
}