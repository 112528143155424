.topReuse {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px;
}

.mainReuse {
  /* background-color: #100840; */
  width: 23%;
  height: inherit;
  position: relative;
  max-width: 500px;
  margin: 30px 8px;

}

.mainReuse img {
  width: 100%;
  height: 400px;
  object-fit: scale-down;
  /* border-top-right-radius: 20px;
  border-top-left-radius: 20px; */
}

@media(max-width:1000px) {
  .mainReuse img {
    height: fit-content;
    /* border-top-right-radius: 20px;
    border-top-left-radius: 20px; */
  }
}

.loadmore {
  border-radius: 300px;
  padding: 10px;
  animation: load 700ms linear infinite alternate-reverse forwards;
  height: 34px;
  display: flex;
  align-self: center;
  justify-self: center;
  font-size: 13px;
  align-items: center;
  justify-content: center;
}

@keyframes load {
  0% {
    color: #333;
    transform: translateX(0px);
  }

  100% {
    background-color: #100840;
    color: aliceblue;
    transform: translateX(5px);
  }
}



.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 83%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: last baseline;
  background-color: #00000025;
  color: #111111 !important;
  opacity: 0;
  transition: opacity 0.5s;
  text-decoration: none;
  /* padding: 0 5px; */
}

.mainReuse:hover .overlay {
  opacity: 1;
}

.mainReuse>h4 {
  color: #111111;
  font-size: 15px;
}

.mainReuse>small {
  font-size: 20px;
  color: #111111;
  height: fit-content;
}

.bgMatch {
  position: relative;
  top: -10rem;
  background-color: aliceblue;
  color: #000000;
  width: 50%;
  padding: 4px;
}


.heartProduct {
  /* float: right; */
  /* margin: -10px 17px; */
  transition: all 700ms;
  color: #000000;
}


.heartProduct:hover {
  font-size: larger !important;
  color: #100840 !important;
  border: #100840 solid 1px !important;
  border-radius: 100% !important;
  padding: 5px !important;
  cursor: pointer;
}

.overlay>div {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 7px 10px;
  width: 50%;
  border-radius: 0px 0px 0 12px;
  float: right;
  align-self: flex-end;
}

.overlay>h1 {
  font-size: 12px;
  /* text-shadow: .3em .3em .3em; */
  background-color: rgb(39, 39, 39);
  color: floralwhite;
  padding: 3px 2px;
}



.iconre {
  font-size: 20px;
  color: #000000;
  position: relative;
  z-index: 9;
}

.iconre:hover {
  background-color: #333;
  border-radius: 200px;
  padding: 10px;
  z-index: 0;
  color: #181818;

}

@media(max-width:900px) {
  .mainReuse {
    flex-wrap: wrap;
    width: 40%;
    object-fit: scale-down;
  }
}

@media(max-width:460px) {
  .mainReuse {
    flex-wrap: wrap;
    width: 100%;
    object-fit: scale-down;
  }
}