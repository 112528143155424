.mainCard {
    width: 100%;
    height: 120vh;
    /* background-color: #100840; */
    display: flex;
    justify-content: space-between;
    padding: 45px;
    text-decoration: none;


}

.funday {
    font-size: 30px;
    font-weight: 700;
    color: #555;
}

.cardbtn {
    /* background-color: #4270d2 !important; */
    animation: car 5s running ease-in-out 1s infinite alternate-reverse;
    /* color: aliceblue; */
    margin: 1rem;
    border-top: #0b0047 10px solid;
    border-bottom: #002fc9 10px solid;
    border-left: rgb(0, 10, 65) 10px solid;
    border-right: #3c00c9 10px solid;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    text-decoration: none;
    width: 100%;
    height: 100%;

}


.card-title {
    /* font-size: 30px; */
    font-weight: 800;
    font-variant: small-caps;
}

.card-text {
    font-size: 18px;
}

/* .borderedbtn {
    width: 30%;
    height: 50px;
    padding: 10px;
    border: 5px solid black;

} */
.btn-inner-card {
    width: auto;
    height: inherit;
}

.cardbtn>.btn {
    width: inherit;
    /* background-color: blue !important; */
    height: inherit;

}

.cardbtn>.btn>div {
    width: inherit;
    /* background-color: red !important; */
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.cardbtn>.btn>div>.cardimage {
    width: 100% !important;
    object-fit: scale-down !important;
    height: 70%;
}

@media(max-width:1000px) {
    .cardimage {
        height: auto;
    }

}

@keyframes car {
    0% {
        color: #f80000;
    }

    50% {

        color: #222;
    }

    100% {

        color: #0b0047;
    }
}

@media(max-width:700px) {
    .mainCard {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        height: inherit;
        width: 100%;
        margin: 0;

    }


}

.borcard {

    width: 100%;
    display: flex;
    justify-content: center;
    height: 5rem;
}

.borderedcard {
    background-color: #eee;
    font-size: 20px;
    border: 2px solid #0b0047;
    width: 100%;
    padding: 10px;
    text-transform: capitalize;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

}

.borderedcard:hover {
    background-color: #0b0047;
    color: #eee;
    border: #eee 2px solid;
}

@media(max-width:1000px) {
    .borderedcard {
        font-size: 14px;
        width: 80%;
    }


}