/* PaymentMethodComponent.css */
.payment-method-container {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #00004e;
    width: 100%;
    margin: 0 auto;

}



.payment-method-container-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

@media(max-width:392px) {
    .payment-method-container-inner {
        justify-content: center;

    }
}

.payment-method-container h2 {
    font-size: 1.5rem;
    color: #fff;
}

.payment-method-label {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
    width: 30%;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.paydisabled {

    opacity: 0.2;
}


.payment-method-label>img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    transition: 700ms;

}

.payment-method-label>img:hover{
    border-radius: 300px;
    padding: 8px;

}
.payment-method-label>img.active{
    border-radius: 300px; 
}


@media(max-width:540px) {
    .payment-method-label {
        flex-wrap: wrap;
        width: 50%;
    }



}



@media(max-width:420px) {
    .payment-method-label {
        flex-wrap: wrap;
        width: 50%;
    }



}



@media(max-width:392px) {
    .payment-method-label>img {

        flex-direction: row;
    }
}


.payment-method-input {
    margin-right: 10px;

}

.payment-method-input>img.active{
    border-radius: 50%;
}

.merchant-summary {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.payee {
    margin: 0 2rem;
}

.selected-method {
    font-weight: bold;
    margin: 10px 0;
    /* color: #333; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.selectedMethod {
    background-color: #100840;
    width: 60%;
    color: white !important;
    padding: 10px 2px;
    margin: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.information-summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.shipping-summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.information-summary h2,
.information-summary p {

    padding: 1rem 0;

}

.information-summary p {
    background-color: #fff;
    margin: 1rem 0;
    width: 50%;
    border-radius: 10px 0px;
    box-shadow: #1008404e 1px 1px 10px;
}

.shipping-summary p {
    background-color: #fff;
    margin: 1rem 0;
    width: 50%;
    border-radius: 100px 5px;
    box-shadow: #1008404e 1px 1px 10px;
}

@media(max-width:1000px) {
    .selectedMethod {
        padding: 2rem 0;
        font-size: 14px;
    }

    .orderSummary>h1 {
        font-size: 23px;
        font-weight: 600;
    }


    .selMed {
        font-size: 10px;
    }

    .myPay {
        /* flex-direction: column; */
        flex-wrap: wrap;

    }

    .payLink {
        width: 40%;
    }

    .information-summary p {

        border-radius: 1px;
        width: 80%;

    }


}

.payLink:hover {
    box-shadow: #bdb1ff 5px 5px 5px;
}

/* Add icon styles */
.payment-method-icon {
    font-size: 2rem;
    margin-right: 10px;

}

input[type="radio"] {
    display: none;
}

.orderSummary {
    background-color: #dbd9e4;
    box-shadow: 3px 2px 5px gray;
    padding: 1rem 0;
}

