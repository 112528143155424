.preloader-container {
  display: block;
  height: 30px;
  border-radius: 300px;
  width: 50%;
  /* color: #100048; */
  opacity: 0.3;

  position: relative;
  left: 25%;
}

.preloader-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}