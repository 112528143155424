.checkoutMain {
    padding: 3rem;
    background-color: #fff;
}

.checkout {
    display: grid;
    grid-template-columns: 55% 45%;
    justify-content: space-between;
    background-color: #eee;

}

.checkout__address--header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 0;
    color: #100840;
    text-decoration: none;
}

.checkout__address--header h1 {
    width: max-content;
    font-size: clamp(3em, 2vw, 4em);
    margin: .25em 0;
}

.checkout__address--header-crumbs {
    display: flex;
}

.checkout__address--header-crumbs a {
    text-transform: uppercase;
    color: #222;
    text-decoration: none;
    margin: 0 .35em;
}

.checkout__address--header-crumbs :not(a:last-child):after {
    /* content: '>'; */
    margin: 0 .5em;
}

.checkout__address--header-crumb {
    opacity: .5;
    font-size: .85rem;
}


.checkout__address--links {
    margin: 2em 0;
    display: flex;
    justify-content: center;
    background-color: #100840;
    align-items: center;
    height: 50px;
    border-radius: 300px;

}



.checkout__address--links a {
    color: var(--white) !important;
    border: none;
    text-decoration: none;
    cursor: pointer;
    width: 100%;

}

@media(max-width:435px) {
    .checkout__address--links a {
        font-size: 10px;
        padding: 0 30px;
        display: flex;
        align-items: center;

    }
}

.checkout__address--links button {
    background-color: transparent;
    border: none;
}

.form__input>label {
    font-size: 17px;
}

.form__input>input {
    font-size: 17px;
}

.checkout__table--list>span {
    font-size: 17px;
}

.checkout__preview--section>span {
    font-size: 17px;
}



.checkout__address--footer__buttons button:nth-child(1)::before {
    content: '<';
    margin-right: .5em;
}

.checkout__address-choice input {
    margin-right: .5em;
    font-weight: bold;
}

.checkout__address-ship,
.checkout__address-ship h1 {
    margin: 2em 0;
}


.checkout__preview {
    width: 85%;
    min-height: 100vh;
    padding: 1em;
    background-color: #fff;
    margin: 5rem;
}

.checkout__preview--section {
    width: 100%;
    border-bottom: 1px solid #DDD;
    padding: 1em 0;
}

.checkout__preview--coupon button {
    border: none;
    background-color: #180c5e;
    padding: 10px 28px;
    color: #eee;

}

.checkout__preview--coupon button:hover {
    background-color: #100840;
    color: #fff;
}

.checkout__preview--coupon .coupon {
    margin: 1em 0;
}

.checkout__preview--coupon .coupon input {
    width: 100%;
    padding: .5em;
}

.checkout__preview--coupon .coupon button {
    width: max-content;
    background-color: #100840;
    margin: .5em 0;
    padding: 1em 2em;
    font-size: .75rem;
}

.checkout__preview--prices {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkout__preview--prices span {
    color: #100840;
    font-weight: 700;
}

.checkout__note {
    padding: 1em;
    margin: 1em 0;
    background-color: #100840;
    border-radius: 1em;
    color: rgb(227, 227, 227);
}

.checkout__note,
.checkout__note b {
    font-size: .9rem;
}

.checkout__note b {
    margin-right: .25em;
}

.checkout__table {
    width: 100%;
    margin: 2em 0;
}

.checkout__table tr td {
    text-align: center;
}

.checkout__table section {
    display: flex;
    justify-content: space-between;
    padding: .5em 0;
}

.checkout__table section {
    width: 100%;
    /* text-align:right; */
}


.checkout__table--head {
    display: flex;
    border-bottom: 1px solid #000;
    padding: 1em 0;
}

.checkout__table--list {
    opacity: .85;
    padding: 1em 0;
    border-bottom: 1px solid gray;
}

.checkout__table--list:last-child {
    border-bottom: none;
}

.checkout__shipping h1 {
    font-size: clamp(1.5em, 2vw, 2em);
    margin: 1em 0;
}

.checkout__shipping--list {
    width: 100%;
    padding: 1em;
    border-radius: .5em;
    border: 1px solid #222;
}

.checkout__shipping--list li {
    width: 100%;
    list-style-type: none;
    border-bottom: 1px solid var(--black-dark);
    padding: .5em 0;
}

.checkout__shipping--list li:last-child {
    border: none;
}


.checkout__shipping--section {
    margin: 2em 0;
}

.checkout__shipping--note textarea {
    width: 100%;
    margin: 1em 0;
    resize: horizontal;
    padding: .25em;
}

.checkout__shipping--detail-one li {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.checkout__shipping--detail-one li span:first-child {
    width: max-content;
}

.checkout__shipping--detail-one li span:nth-child(2) {
    min-width: 60%;
    opacity: .8;
}

.checkout__shipping--detail-one li a {
    color: #222;
    opacity: .8;
}

.checkout__shipping--detail-two {
    justify-content: flex-start;
}

.checkout__shipping--detail-two label {
    margin-left: .25em;
}

.active-crumb {
    opacity: 1;
}

/* welcome back page */
.welcomeBackCheckout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem;

}

.innWelcome {
    background-color: #999999;
    display: flex;
    width: 70%;
    border-radius: 10px;
    flex-direction: column;
    /* height: 50vh; */
    padding: 5rem 0;
}

.innWelcome>h1 {
    color: #fff;
}

.innWelcome>h1>small {
    font-size: 17px;
}

.innerFold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    width: 70%;
    align-self: center;
    margin: 2rem 0;
    padding: 6rem 0;
}

.innerFold>img {
    width: 10%;
    padding: 10px;
    background-color: #100840b5;
    border-radius: 300px;
    margin: 10px 0;
}


@media (max-width: 50em) {
    .checkout__preview {
        margin: 2em 0;
        width: 100%;

    }

    .checkout {
        grid-template-columns: 100%;
    }

    .checkout__shipping--detail-one li {
        flex-direction: column;
        justify-content: space-between;
    }

    .checkout__shipping--detail-one li span:nth-child(2) {
        margin: 1em 0;
    }
}

.myPay {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c003f;
    align-self: center;
    padding: 30px 0;
    margin: 3rem 0;
    box-shadow: #cfcfcf 1px 1px 5px;
}

.payLink {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #DDD;

}

.payLink>img {
    width: 70%;
    padding: 10px 5px;
    border: 2px double aliceblue;
    transition: all 700ms;
}

.payLink>img:hover {
    transform: translate(20px);
    transform: scale(1.2);
}

.payLink>img.active{
    border-radius: 50%;
}
.partnerid {
    color: #fff;
    padding: 2px;
    font-variant: small-caps;
    text-decoration: none;
}