/* AccessoryList.css */
.AppAccess {
    margin: 1rem 0;
    padding: 1rem 0;
}

.accessory-list-container {
    width: 100%;
    white-space: nowrap;
    padding: 3px 40px;
    background-color: #f0f0f0;
    margin-left: 10px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.accessory-list-container:hover {
    background-color: #cfcfcf;
}

.accessory-list {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accessory-item {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
}

.accessory-item:hover {
    color: #000131;
}