.toggleNavbar {
  background-color: #100840;
  height: 1.8rem;
  padding: 0 !important;
  /* justify-content: center;
  display: flex;
  align-items: flex-end; */
  display: none;


}

.toggleNavbar>button {
  padding: 5px 20px;
  /* border: none; */
  outline: none;
  /* transform: skewX(-15deg); */
  background-color: #333;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  color: #fff;
  display: none;
}

.toggleNavbar>button span {
  /* transform: skewX(15deg); */
}


@media(max-width:1000px) {
  .toggleNavbar {
    align-items: flex-start;
    display: none;
  }
}

.video-overlay {
  position: relative;
}

.video-overlay video {
  width: 100%;
  height: auto;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-content h1 {
  font-size: 8rem;
  margin: 0 0 10px;
}

.overlay-content p {
  font-size: 2rem;
  margin: 0;
}

@media(max-width:769px) {
  .overlay-content>h1 {
    font-size: 2rem;
  }

  .overlay-content>p {
    font-size: 1rem;
  }

  .video-overlay video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
}

.imagedivider {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -6.7rem; */
  padding: 0;
  background-color: #8c98b6bd;
  justify-content: center;

}

.imagedivider>div {
  width: 50%;
  height: 60vh;

}

.imagedivider>.women,
.imagedivider>.men,
.imagedivider>.kids,
.imagedivider>.fitness {
  width: 46%;
  height: 150vh;
  background-size: cover;
  /* background:linear-gradient(#10084086,#00000038); */
}

@media(max-width:1000px) {
  .imagedivider {
    flex-direction: column;
    /* margin-left: 0rem; */

  }

  .imagedivider>.women,
  .imagedivider>.men,
  .imagedivider>.kids,
  .imagedivider>.fitness {
    width: 100%;
    height: 130vh;
    background-size: cover;
    background-position: center;
  }
}

.btnhome {
  display: flex;
  justify-content: left;
  align-items: center;
  height: inherit;
  width: 100%;
  /* padding: 0 2.8rem; */
}

.btnhome1 {
  display: flex;
  justify-content: right;
  align-items: center;
  height: inherit;
  width: 100%;
  /* padding: 0 5rem; */

}

.btnhomelink {
  border-left: #100840 1px solid;
  background-color: #fff;
  color: #100840;
  padding: 10px 25px;
  text-decoration: none;
  font-size: large;
  box-shadow: #1008403a 2px 3px 10px;
  /* margin-left: 2rem; */

}

.ads {
  width: 100%;
  height: 3rem;
  background-color: #100840;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 38px 0;

}

.ads>p {
  font-size: 14px;
  color: aliceblue;
}

.ads>.link {
  font-size: 15px;
  color: aliceblue;
  text-decoration: none;
}


.ads1 {
  width: 100%;
  height: 4rem;
  background-color: #100840;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 20px;

}

.ads1>p {
  font-size: 12px;
  color: aliceblue;
}

.ads1>.left>.link,
.ads1>.right>.link {
  font-size: 15px;
  color: aliceblue;
  text-decoration: none;
  border: aliceblue solid 2px;
  padding: 5px 15px;
  margin: 5px 0;

}


.ads1>.right {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

@media(max-width:1000px) {
  .ads1>p {
    font-size: 9px;

  }

  .ads1>.left>.link,
  .ads1>.right>.link {
    font-size: 10px;

  }

  .ads>p {
    font-size: 11px;

  }

  .ads>.link {
    font-size: 12px;

  }
}