.topbg {
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(#100840, #00000078), url(../img/women/_PHL2037\ \(FILEminimizer\).JPG);
  background-size: cover;
  background-position: center;

}

.topcolor {
  width: 100%;
  height: 20vh;
  background-color: #333;

}

.topcolor>h5 {
  color: aliceblue;
  font-size: 14px;
  transition: all 1s;
}

.topcolor>h5:hover {
  font-size: 13px;
}

.topic {
  background: conic-gradient(rgb(0, 136, 255) 0% 21.25%, #070401 21.25% 40.95%, #ffc800 40.95% 59%, #100840 59% 78.75%, rgb(255, 0, 179) 78.75% 100%);
  padding: 15px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: clip 5s linear infinite;
  font-size: 13px;
}

@keyframes clip {
  0% {
    clip-path: polygon(50% 20%, 100% 38%, 82% 100%, 18% 100%, 50% 50%);
  }

  20% {
    clip-path: polygon(50% 50%, 100% 38%, 82% 100%, 18% 100%, 20% 38%);
  }

  40% {
    clip-path: polygon(50% 20%, 50% 50%, 82% 100%, 18% 100%, 20% 38%);
  }

  60% {
    clip-path: polygon(50% 20%, 100% 38%, 50% 50%, 18% 100%, 20% 38%);
  }

  80% {
    clip-path: polygon(50% 20%, 100% 38%, 82% 100%, 50% 50%, 20% 38%);
  }

  100% {
    clip-path: polygon(50% 20%, 100% 38%, 82% 100%, 18% 100%, 50% 50%);
  }
}

.iconwithtitlel {
  margin: 30px 0;
}

.dynamic {
  width: 100%;
  padding: 20px;
  background-color: #333;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.question {
  font-size: 17px;
  margin: 0px 0;
  background-color: #100840;
  padding: 20px;
  cursor: grab;

}

.answer {
  font-size: 14px;
  padding: 22px;
  background-color: #333;
  border-radius: 12px;
  color: aliceblue;
  margin: 20px 0;
}

.contact {
  padding: 60px 0;
  width: 100%;
}

.contact>.intouch {
  text-transform: capitalize;
  font-variant: small-caps;
}

.contact>.con {
  background: #100840;
  width: 100%;
  height: 50vh;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-repeat: repeat-x; */
  background-position: center;
  color: aliceblue;

}

.contact>.con>button {
  padding: 15px 75px;
  border: 2px double #100840;
  background-color: rgba(240, 248, 255, 0.503);
  font-size: large;
}

.btnlink {
  font-size: large;
  color: #ffffff;
  text-decoration: none;
}

/* touch */

.touch {
  padding: 60px 0;
  width: 100%;
  display: flex;
}

.touch>.intouch {
  text-transform: capitalize;
  font-variant: small-caps;
}

.touch>.leftside {
  width: 100%;
  /* height: 100vh; */
  background-color: #100840;
}

.touch>.leftside>.con {
  background-image: url(../img/women/But\ lift\ b\ \(FILEminimizer\).jpg);
  width: 100%;
  height: 60vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;

}

.touch>.leftside>.con>span {
  padding: 15px 75px;
  /* border: 2px double #100840; */
  background-color: rgba(240, 248, 255, 0.668);
  font-size: large;
  color: #333;
}

.touch>.leftside>.con>h5 {
  color: aliceblue;
  background-color: #333;
  padding: 10px;
  margin: 10px 0;
}

.btnlink2 {
  font-size: large;
  color: #333;
  text-decoration: none;
}

.virtualAsist {
  width: 100%;
  height: inherit;
  background-color: #100840;
}

.rightsidebar {
  width: 30%;
  height: inherit;
  display: flex;
  background-color: 100840;
  margin: 0 5px;
  padding: 15px 0;
}

@media(max-width:900px) {
  .touch {
    flex-wrap: wrap;
  }

  .leftside {
    width: 100%;
  }

  .rightsidebar {
    width: 100%;
  }
}