/* DeliveryMethodComponent.css */
.delivery-method-container {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f8f8f8;
    width: 100%;
    margin: 0 auto;
    
}

.delivery-inner {
    display: flex;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    margin: 20px;
    background-color: #c3b9fd;
    align-items: center;
    padding: 10px;
}

.delivery-method-container h2 {
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;

}

.delivery-method-label {
    /* display: flex; */
    /* margin: 20px 0; */
    font-weight: bold;
    /* justify-content: left; */
    /* align-items: center; */
    cursor: pointer;


}

@media(max-width:1000px) {

    .delmerch {
        font-size: 16px;
    }
}

.delivery-method-input {
    /* margin-right: 10px; */

}

.selected-method {
    font-weight: bold;
    margin: 10px 0;
    /* color: #333; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.selectedMethod {
    background-color: #100840;
    width: 60%;
    color: white !important;
    padding: 10px 2px;
    margin: 20px;
}