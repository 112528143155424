.mainTrends {
    width: 100%;
    height: 20vh;
    background-color: #fff;
}

.mainTrends>hr {
    background-color: #d4d4d4;
}

.trendlogo {
    width: 100%;
    margin: 50px 0 0;
    border-bottom: rgb(218, 218, 218) 1px solid;
    /* background-color: aqua; */


}

.trendlogo>img {
    width: 10%;

}

.trendbanner {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    margin: 25px 0;
    /* background-color: #333;   */
    border-radius: 50px 1px;
    padding-bottom: 20px;
}

.trendbanner>.img {
    width: 100%;

}

.trendbanner>.img>img {
    width: 20%;
    object-fit: cover;
    transition: all 700ms;
    height: inherit;
}

@media(max-width:760px) {
    .trendbanner>.img>img {
        width: 30%;

    }
}

@media(max-width:600px) {
    .trendbanner>.img>img {
        width: 32%;

    }
}


.trendbanner>.img>img:hover {
    opacity: 0.6;
    transform: scale(0.8);
}

@media(max-width:900px) {
    .mainTrends {
        max-height: 10vh;
        /* position: relative; */
        /* top: -5rem; */
    }

    .trendbanner {
        max-height: 10vh;
    }

    .trendbanner>.img {
        max-height: 10vh;
    }
}