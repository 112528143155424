.breadcrumb {
    display: flex;
    align-items: center;
    font-size: 16px;
    /* margin: 1rem 8rem; */
    background-color: #e2e8ee60;
    padding: 1rem 2rem;
    border-bottom: #e6e6e6 solid 2px;
    padding: 0 1rem;

}

.breadcrumb a {
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s;
}

.breadcrumb a:hover {
    color: #0056b3;
}

.breadcrumb span {
    color: #333;
    margin-top: 5px;
    margin-left: 5px;
}