.input__group{
    margin-bottom:20px;
}

.selectCate h4{
    margin-top:30px; 
}


/* Pop up page*/
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fff;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
  }
  
  .modal-body {
    /* Customize the body styling as per your needs */
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .fieldsetStyle {
		/* border: 'none, */
		border: 1px solid black;
		padding: 10px;
    margin-bottom: 30px;
	  }
	
	  .legendStyle  {
		display: inline-block;
		/* padding: 0 10px; */
		font-weight: bold;
		position: relative;
		margin-top: -23px;
		background-color: #fff;
    display:inline;
	  };
 