.mainPrompt {
  width: 30%;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: rgb(172, 172, 172);
  margin-top: 10px;
}

.prompt-dialog-top {
  margin-bottom: 20;
  margin-top: 0;
  padding-top: 0;
}

.prompt-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}


.prompt-dialog {
  background-color: white;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 35%;
  min-height: 30vh;

}

@media(max-width:1000px) {
  .prompt-dialog-overlay {
    height: 100%;
    width: 100%;

  }

  .prompt-dialog {
    width: 90%;
    min-height: 15vh;
    height: 28vh;

  }
}


.prompt-dialog h3 {
  margin-top: 30px;
}

.prompt-dialog input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.prompt-dialog .button-container {
  display: flex;
  justify-content: flex-end;


}

.prompt-dialog button {
  margin-left: 10px;
  width: 20%;
  height: 30px;
  padding: 5px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #100840;
  margin-top: 10px;
  color: aliceblue
}

/* Center the title text in the tooltip */
.center-tooltip {
  text-align: center;
}